import React, {  } from 'react'
import OtpModal from "../components/OtpModal/OtpModal";
import Popup from '../components/ButtonPopup/Popup';
import IndexPage from './IndexPage';
import { user } from '../Data/auth';
import LocationModal from '../components/LocationModal/LocationModal';


export default function Home() {

    return (
        <div className="grid sm:grid-cols-2 font-[Inter] h-screen relative">
            {/* <InstallPWA /> */}
            {user && <div className='absolute right-5 top-10 '>
                <Popup />
            </div>}
            {!user ? <div className="flex justify-center items-center border rounded-2xl">
                <OtpModal />
            </div> :
                <div>
                    {localStorage.getItem('loc') ? <IndexPage /> : 
                        <div className='flex justify-center items-center h-screen flex-col text-center p-8 gap-5'>
                             Click here to see how to enable location if you denied it.
                            <LocationModal />
                        </div>
                    }
                </div>
            }
        </div>
    )
}
