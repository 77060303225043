import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { Table, Button } from 'rsuite';
// import { mockUsers } from './mock';

function reverse(array) {
    let newArr = [];
    let i;
    for(i = array.length-1; i >= 0; i--) {
      newArr.push(array[i]);
    }
    return newArr;
  }

export default function DataGrid({data}) {
    const { Column, HeaderCell, Cell } = Table;
    const navigate = useNavigate()
    
    let reverseData = reverse(data);
    reverseData.map((data, key) => {
        data.key = key+1;
    })

    
    return (
        <Table
            data={reverseData}
            onRowClick={rowData => {
                console.log(rowData);
            }}
            height={300}
            className='border'
        >
            <Column width={50} align="left">
                <HeaderCell className='font-semibold text-gray-700'>Id</HeaderCell>
                <Cell dataKey="key" />
            </Column>
            <Column width={150} align="left">
                <HeaderCell className='font-semibold text-gray-700'>Enterprise Name</HeaderCell>
                <Cell dataKey="enter_name" />
            </Column>

            <Column width={140}>
                <HeaderCell className='font-semibold text-gray-700'>District</HeaderCell>
                <Cell dataKey="district" />
            </Column>
            <Column width={140}>
                <HeaderCell className='font-semibold text-gray-700'>Date</HeaderCell>
                <Cell dataKey="date" />
            </Column>
            <Column width={80}>
                <HeaderCell className='font-semibold text-gray-700'>Action</HeaderCell>
                <Cell style={{ padding: '6px 6px 6px 0px' }}>
                    {rowData => (
                        <Button appearance="link" onClick={() => navigate('/previous_details', { state: { data: rowData } })}>
                            View
                        </Button>
                    )}
                </Cell>
                
                
            </Column>
            <Column width={80}>
                <HeaderCell></HeaderCell>

                <Cell style={{ padding: '6px 6px 6px 0px' }}>
                    {rowData => (
                        <Button appearance="link" onClick={() => navigate('/update_form', { state: { data: rowData } })}>
                            Edit
                        </Button>
                    )}
                </Cell>
            </Column>
        </Table>
    )
}
