import axios from "axios";
import { customRequest } from "./auth";


export const generateRefreshToken = (refreshaccessToken) => {
 
    const Body = {
        "refresh_token": refreshaccessToken
    }
    const data = customRequest
    .post('/users/token', Body)
        .then((response) => {
            const data = response.data;
            console.log(data);
            localStorage.setItem('accessToken', data?.token);
            console.log(localStorage.getItem('accessToken'));
            return data;
        })
        .catch((error) => {
            return error;
        });
    return data;
};
