import React, { Children, useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./Carousel.css";
import { useNavigate } from "react-router-dom";

export const CarouselItem = ({ children, width, height }) => {
    return (
        <>
            <div className="carousel-item " style={{ width: width, height: height }}>
                {children}
            </div>
        </>
    );
};


const Carousel = ({ children, updateIndex }) => {
    const [paused, setPaused] = useState(false);

    const Children = []
    children.forEach(data => {
        if (Object.keys(data.props).length !== 0) {
            Children.push(data)
        }
    });

    return (
        <div
            // {...handlers}
            className="carousel w-screen sm:w-full relative "
            onMouseEnter={() => setPaused(true)}
            onMouseLeave={() => setPaused(false)}
        >
            <div
                className="inner"
                style={{ transform: `translateX(-${updateIndex * 100}%)`}}
            >
                {React.Children.map(Children, (child, index) => {
                    return React.cloneElement(child, { width: "100%", height: "100vh" });
                })}
            </div>
        </div>
    );
};

export default Carousel;
