import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Panel, PanelGroup, Placeholder } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import axios from "axios";
import { customRequest, user } from "../Data/auth";
import Loader from "../components/Loader";
import { generateRefreshToken } from "../Data/generateToken";

export default function Preview() {
  const location = useLocation();
  const [data, setData] = useState(location?.state?.preview);
  console.log(data);
  const navigate = useNavigate();
  const [loc, setLoc] = useState("");
  const successCallback = (position) => {
    let api_key = "7ae3daa9f6d1400cbfaf1262e653f499";
    let api_url = "https://api.opencagedata.com/geocode/v1/json";
    let req_url =
      api_url +
      "?" +
      "key=" +
      api_key +
      "&q=" +
      encodeURIComponent(
        position?.coords.latitude + "," + position?.coords.longitude
      ) +
      "&pretty=1" +
      "&no_annotation=1" +
      "&language=en";
    axios
      .get(req_url)
      .then((response) => setLoc(response.data?.results[0].formatted))
      .catch((error) => console.log(error.message));
  };

  const errorCallback = (error) => {
    // console.log(error);
  };
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }, []);

  const previousData = location.state?.sendData;

  const sendValues = {
    ...previousData,
    ...location.state?.imageData,
    geolocation: localStorage.getItem("loc"),
    user_id: user.id,
  };

  const updateValues = {
    ...previousData,
    trade_license_photo: location.state.imageData?.trade_license_photo,
    dm_lic_photo: location.state.imageData?.dm_lic_photo,
    peso_lic_photo: location.state.imageData?.peso_lic_photo,
    neeri_lic_photo: location.state.imageData?.neeri_lic_photo,
    noc_lic_photo: location.state.imageData?.noc_lic_photo,
    pcb_cto_photo: location.state.imageData?.pcb_cto_photo,
    enter_photo: location.state.imageData?.enter_photo,
    geolocation: localStorage.getItem("loc"),
    id: location.state?.id,
  };

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const api =
    window.location.pathname === "/preview"
      ? "/users/send-form-data "
      : "/users/update-form-data";
  const value =
    window.location.pathname === "/preview" ? sendValues : updateValues;

  const finalSubmitHandler = async () => {
    setLoading(true);
    customRequest
      .post(api, value, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer${localStorage.getItem("accessToken")}`,
        },
      })
      .then(async (response) => {
        setLoading(false);
        if (response.data.status === "Success") {
          setSuccess(true);
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          if (response.data.status === 403) {
            const newTokenData = await generateRefreshToken(
              localStorage.getItem("refreshToken")
            );
            console.log(newTokenData.token);
            if (newTokenData.token) {
              localStorage.setItem("accessToken", newTokenData.token);
              finalSubmitHandler();
            } else {
              localStorage.clear();
            }
          } else if (response.data.status === 440) {
            localStorage.clear();
          }
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="flex flex-col p-3 relative">
      <i
        className="fa-solid fa-arrow-left text-2xl absolute left-5 top-10 z-50"
        onClick={() =>
          window.location.pathname === "/preview"
            ? navigate("/add_new_form", {
                state: {
                  data: previousData,
                  imageData: location.state.imageData,
                },
              })
            : navigate("/update_form", { state: { data: previousData } })
        }
      ></i>

      {!success ? (
        <div>
          <div className="text-center text-3xl font-semibold my-6">Preview</div>
          <PanelGroup accordion defaultActiveKey={1} bordered>
            <Panel header="Basic Information" eventKey={1} id="panel1">
              <div className="flex flex-col gap-4">
                <div>
                  <span className="font-semibold">Enterprise Name : </span>
                  {data?.enter_name}
                </div>
                <div>
                  <span className="font-semibold">
                    Enterprise Address name :{" "}
                  </span>
                  {data?.addr_name}
                </div>
                <div>
                  <span className="font-semibold">Police station : </span>
                  {data?.police_station}
                </div>
                <div>
                  <span className="font-semibold">District : </span>
                  {data?.district}
                </div>
                <div>
                  <span className="font-semibold">Pincode : </span>
                  {data?.pincode}
                </div>
                <div>
                  <span className="font-semibold">Name of owner : </span>
                  {data?.own_name}
                </div>
                <div>
                  <span className="font-semibold">Mobile no : </span>
                  {data?.mob_number}
                </div>
              </div>
            </Panel>
            <Panel header="All Licenses and Photos" eventKey={2} id="panel2">
              <div className="flex flex-col gap-4">
                <div>
                  <span className="font-semibold">Trade License : </span>
                  {data?.trade_license === true ||
                  data?.trade_license === "true"
                    ? "Yes"
                    : "No"}
                </div>
                <div>
                  <span className="font-semibold">Trade License no : </span>
                  {data?.trade_license_number}
                </div>
                <div className="flex gap-3">
                  <span className="font-semibold">Trade License Photo : </span>
                  {location.state.imageData?.trade_license_photo ? (
                    <img
                      src={location.state.imageData?.trade_license_photo}
                      alt=""
                      width={100}
                      height={100}
                    />
                  ) : (
                    <img
                      src={data?.trade_license_photo}
                      alt=""
                      width={100}
                      height={100}
                    />
                  )}
                </div>
                <div>
                  <span className="font-semibold">
                    Capacity (Greater than 500kg) :{" "}
                  </span>
                  {data?.capacity === true || data?.capacity === "true"
                    ? "Yes"
                    : "No"}
                </div>
                <div>
                  <span className="font-semibold">DM Permission : </span>
                  {data?.dm_permission === true ||
                  data?.dm_permission === "true"
                    ? "Yes"
                    : "No"}
                </div>
                <div>
                  <span className="font-semibold">DM License : </span>
                  {data?.dm_license}
                </div>
                <div className="flex gap-3">
                  <span className="font-semibold">DM License Photo : </span>
                  {location.state.imageData?.dm_lic_photo ? (
                    <img
                      src={location.state.imageData?.dm_lic_photo}
                      alt=""
                      width={100}
                      height={100}
                    />
                  ) : (
                    data.dm_license && (
                      <img
                        src={location.state.preview?.dm_lic_photo}
                        alt=""
                        width={100}
                        height={100}
                      />
                    )
                  )}
                </div>
                <div>
                  <span className="font-semibold">PESO Permission : </span>
                  {data?.peso_permission === true ||
                  data?.peso_permission === "true"
                    ? "Yes"
                    : "No"}
                </div>
                <div>
                  <span className="font-semibold">PESO License no : </span>
                  {data?.peso_license_no}
                </div>
                <div className="flex gap-3">
                  <span className="font-semibold">PESO License Photo : </span>
                  {location.state.imageData?.peso_lic_photo ? (
                    <img
                      src={location.state.imageData?.peso_lic_photo}
                      alt=""
                      width={100}
                      height={100}
                    />
                  ) : (
                    data?.peso_lic_photo && (
                      <img
                        src={data?.peso_lic_photo}
                        alt=""
                        width={100}
                        height={100}
                      />
                    )
                  )}
                </div>
                <div>
                  <span className="font-semibold">
                    NEERI Formulation Obtained :{" "}
                  </span>
                  {data?.neeri_form === true || data?.neeri_form === "true"
                    ? "Yes"
                    : "No"}
                </div>
                <div>
                  <span className="font-semibold">NEERI License no : </span>
                  {data?.neeri_license_no}
                </div>
                <div className="flex gap-3">
                  <span className="font-semibold">NEERI License Photo : </span>
                  {location.state.imageData?.neeri_lic_photo ? (
                    <img
                      src={location.state.imageData?.neeri_lic_photo}
                      alt=""
                      width={100}
                      height={100}
                    />
                  ) : (
                    data?.neeri_lic_photo && (
                      <img
                        src={data?.neeri_lic_photo}
                        alt=""
                        width={100}
                        height={100}
                      />
                    )
                  )}
                </div>
                <div>
                  <span className="font-semibold">NOC License : </span>
                  {data?.noc_license === true || data?.noc_license === "true"
                    ? "Yes"
                    : "No"}
                </div>
                <div>
                  <span className="font-semibold">NOC License no : </span>
                  {data?.noc_license_no}
                </div>
                <div className="flex gap-3">
                  <span className="font-semibold">NOC License Photo : </span>
                  {location.state.imageData?.noc_lic_photo ? (
                    <img
                      src={location.state.imageData?.noc_lic_photo}
                      alt=""
                      width={100}
                      height={100}
                    />
                  ) : (
                    data?.noc_lic_photo && (
                      <img
                        src={data?.noc_lic_photo}
                        alt=""
                        width={100}
                        height={100}
                      />
                    )
                  )}
                </div>
              </div>
            </Panel>
            <Panel header="WBPCB Details" eventKey={3} id="panel3">
              <div className="flex flex-col gap-4">
                <div>
                  <span className="font-semibold">WBPCB CTE / CTO : </span>
                  {data?.pcbcte_cto === true || data?.pcbcte_cto === "true"
                    ? "Yes"
                    : "No"}
                </div>
                <div>
                  <span className="font-semibold">CTE / CTO Number : </span>
                  {data?.pcb_cto}
                </div>
                <div className="flex gap-3">
                  <span className="font-semibold">CTE / CTO Photo : </span>
                  {location.state.imageData?.pcb_cto_photo ? (
                    <img
                      src={location.state.imageData?.pcb_cto_photo}
                      alt=""
                      width={100}
                      height={100}
                    />
                  ) : (
                    data?.pcb_cto_photo && (
                      <img
                        src={data?.pcb_cto_photo}
                        alt=""
                        width={100}
                        height={100}
                      />
                    )
                  )}
                </div>
                <div>
                  <span className="font-semibold">Geo Location : </span>
                  {loc}
                </div>
                <div className="flex gap-3">
                  <span className="font-semibold">Enterprise Photo : </span>
                  {location.state.imageData?.enter_photo ? (
                    <img
                      src={location.state.imageData?.enter_photo}
                      alt=""
                      width={100}
                      height={100}
                    />
                  ) : (
                    data?.enter_photo && (
                      <img
                        src={data?.enter_photo}
                        alt=""
                        width={100}
                        height={100}
                      />
                    )
                  )}
                </div>
                <div>
                  <span className="font-semibold">
                    Approx Area (In Sq. feet) :{" "}
                  </span>
                  {data?.approx_area}
                </div>
                <div>
                  <span className="font-semibold">
                    Firecrack Machinary Types :{" "}
                  </span>
                  {data?.fire_machine}
                </div>
              </div>
            </Panel>
          </PanelGroup>
          <div className="flex mt-5 gap-4">
            <button
              className="p-2 px-8 rounded-md font-bold bg-indigo-200"
              onClick={finalSubmitHandler}
            >
              Final Submit
            </button>
            <button
              className="p-2 px-8 rounded-md font-bold bg-slate-100"
              onClick={() =>
                window.location.pathname === "/preview"
                  ? navigate("/add_new_form", {
                      state: {
                        data: previousData,
                        imageData: location.state.imageData,
                      },
                    })
                  : navigate("/update_form", { state: { data } })
              }
            >
              Edit
            </button>
          </div>
        </div>
      ) : loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col items-center space-y-5 h-screen justify-center">
          <i className="fa-solid fa-circle-check fa-beat-fade text-green-600 text-6xl"></i>
          <div className="text-green-600 text-xl whitespace-nowrap">
            Data submitted successfully
          </div>
        </div>
      )}
    </div>
  );
}
