import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { wbpcb } from "../assets";
import DataGrid from "../components/DataGrid/DataGrid";
import { customRequest } from "../Data/auth";
import { user } from "../Data/auth";
import { generateRefreshToken } from "../Data/generateToken";

export default function IndexPage() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      customRequest
        .get(`/users/get-form-data?user_id=${user.id}`, {headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer${localStorage.getItem("accessToken")}`,
        }})
        .then((response) => {
          setData(response.data.data);
        })
        .catch(async (error) => {
          console.log(error);
          if (error.response.status === 403) {
            const newTokenData = await generateRefreshToken(
              localStorage.getItem("refreshToken")
            );
            console.log(newTokenData.token);
            if (newTokenData.token) {
              localStorage.setItem("accessToken", newTokenData.token);
              fetchData();
            } else {
              localStorage.clear();
            }
          } else if (error.response.status === 440) {
            localStorage.clear();
          }
        });
    };

    fetchData()
  }, []);

  return (
    <div className="flex flex-col ">
      <div className="flex justify-center items-center text-center flex-col py-10">
        <div className="px-12">
          <img src={wbpcb} alt="" className="w-fit" />
        </div>
        <div className="text-lg pt-8 font-bold ">
          FIRECRACKER MANUFACTURE UNIT
          <br /> INFORMATION FORM
        </div>
      </div>
      <div className="flex gap-3 mt-8 justify-between p-5 items-center">
        <span
          className="border p-2 px-4 font-semibold rounded-lg active:bg-teal-200"
          onClick={() => navigate("/add_new_form")}
        >
          Add New Unit
        </span>
      </div>
      <div className="px-5 flex flex-col gap-5 mt-5">
        <DataGrid data={data} />
      </div>
    </div>
  );
}
