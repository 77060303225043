import React from 'react'
import { Popover, Whisper, Button, ButtonToolbar } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { user } from '../../Data/auth'
// import { Alert } from 'rsuite';

export default function Popup() {
    return (
        <div>
            <Whisper
                placement="bottomEnd"
                trigger="click"
                speaker={<Popover arrow={true}>
                    <div className='text-base py-3 pl-3'><span className='font-semibold'>Name:</span> {user.name}<br /></div>
                    <div className='text-base pb-3 pl-3'><span className='font-semibold'>Police Station:</span> {user.police_station}<br /></div>
                    <div className='text-base pb-3 pl-3'><span className='font-semibold'>District:</span> {user.district}<br /></div>
                    <hr />
                    <div className='text-base py-3 pl-3 text-red-500' onClick={() => (window.localStorage.clear(), window.location.reload())} ><span className='font-semibold'><i className="fa-solid fa-right-from-bracket"></i></span> Logout<br /></div>
                </Popover>}
            >
                <Button><i className="fa-solid fa-user text-xl"></i></Button>
            </Whisper>
            {/* <ButtonToolbar>
                <Button onClick={() => Alert.info('This is a informations.')}> Info </Button>
            </ButtonToolbar> */}
        </div>
    )
}
