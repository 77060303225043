import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Panel, PanelGroup } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';
import axios from 'axios';


export default function PreviousDetails() {
    const navigate = useNavigate();
    const {state} = useLocation();
    const data = state?.data;
    const [loc, setLoc] = useState("")
    const successCallback = (position) => {
        let api_key = "7ae3daa9f6d1400cbfaf1262e653f499"
        let api_url = "https://api.opencagedata.com/geocode/v1/json"
        let req_url = api_url
            + "?"
            + "key=" + api_key
            + "&q=" + encodeURIComponent(position?.coords.latitude + "," + position?.coords.longitude)
            + "&pretty=1"
            + "&no_annotation=1"
            + "&language=en";
        axios
            .get(req_url)
            .then((response) => setLoc(response.data?.results[0].formatted))
            .catch((error) => console.log(error.message));
    };

    const errorCallback = (error) => {
        console.log(error);
    };
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }, []);
    return (
        <div className='flex flex-col p-3'>
            <span className='text-2xl font-bold py-10 flex w-full justify-between'> <i className="fa-solid fa-arrow-left" onClick={() => navigate('/')}></i> <div className='text-center mr-4'>Previous Data</div><div></div></span>
            <PanelGroup accordion defaultActiveKey={1} bordered>
                <Panel header="Basic Information" eventKey={1} id="panel1">
                    <div className='flex flex-col gap-4'>
                        <div><span className='font-semibold'>Enterprise Name : </span>{data?.enter_name}</div>
                        <div><span className='font-semibold'>Enterprise Address name : </span>{data?.addr_name}</div>
                        <div><span className='font-semibold'>Police station : </span>{data?.police_station}</div>
                        <div><span className='font-semibold'>District : </span>{data?.district}</div>
                        <div><span className='font-semibold'>Pincode : </span>{data?.pincode}</div>
                        <div><span className='font-semibold'>Name of owner : </span>{data?.own_name}</div>
                        <div><span className='font-semibold'>Mobile no : </span>{data?.mob_number}</div>
                    </div>
                </Panel>
                <Panel header="All Licenses and Photos" eventKey={2} id="panel2">
                    <div className='flex flex-col gap-4'>
                        <div><span className='font-semibold'>Trade License : </span>{data?.trade_license === true ? "Yes" : "No"}</div>
                        <div><span className='font-semibold'>Trade License no : </span>{data?.trade_license_number}</div>
                        <div className='flex gap-3'><span className='font-semibold'>Trade License Photo : </span>{data?.trade_license_photo && <img src={`${data?.trade_license_photo}`} alt='' width={100} height={100} />}</div>
                        <div><span className='font-semibold'>Capacity (Greater than 500kg) : </span>{data?.capacity === true ? "Yes" : "No"}</div>
                        <div><span className='font-semibold'>DM Permission : </span>{data?.dm_permission === true ? "Yes" : "No"}</div>
                        <div><span className='font-semibold'>DM License : </span>{data?.dm_license}</div>
                        <div className='flex gap-3'><span className='font-semibold'>DM License Photo : </span>{data?.dm_lic_photo && <img src={`${data?.dm_lic_photo}`} alt='' width={100} height={100} />}</div>
                        <div><span className='font-semibold'>PESO Permission : </span>{data?.peso_permission === true ? "Yes" : "No"}</div>
                        <div><span className='font-semibold'>PESO License no : </span>{data?.peso_license_no}</div>
                        <div className='flex gap-3'><span className='font-semibold'>PESO License Photo : </span>{data?.peso_lic_photo && <img src={`${data?.peso_lic_photo}`} alt='' width={100} height={100} />}</div>
                        <div><span className='font-semibold'>NEERI Formulation Obtained : </span>{data?.neeri_form === true ? "Yes" : "No"}</div>
                        <div><span className='font-semibold'>NEERI License no : </span>{data?.neeri_license_no}</div>
                        <div className='flex gap-3'><span className='font-semibold'>NEERI License Photo : </span>{data?.neeri_lic_photo && <img src={`${data?.neeri_lic_photo}`} alt='' width={100} height={100} />}</div>
                        <div><span className='font-semibold'>NOC License : </span>{data?.noc_license === true ? "Yes" : "No"}</div>
                        <div><span className='font-semibold'>NOC License no : </span>{data?.noc_license_no}</div>
                        <div className='flex gap-3'><span className='font-semibold'>NOC License Photo : </span>{data?.noc_lic_photo && <img src={`${data?.noc_lic_photo}`} alt='' width={100} height={100} />}</div>
                    </div>
                </Panel>
                <Panel header="WBPCB Details" eventKey={3} id="panel3">
                    <div className='flex flex-col gap-4'>
                        <div><span className='font-semibold'>WBPCB CTE / CTO : </span>{data?.pcbcte_cto === true ? "Yes" : "No"}</div>
                        <div><span className='font-semibold'>CTE / CTO Number : </span>{data?.pcb_cto}</div>
                        <div className='flex gap-3'><span className='font-semibold'>CTE / CTO Photo : </span>{data?.pcb_cto_photo && <img src={`${data?.pcb_cto_photo}`} alt='' width={100} height={100} />}</div>
                        <div><span className='font-semibold'>Geo Location : </span>{loc}</div>
                        <div className='flex gap-3'><span className='font-semibold'>Enterprise Photo : </span>{data?.enter_photo && <img src={`${data?.enter_photo}`} alt='' width={100} height={100} />}</div>
                        <div><span className='font-semibold'>Approx Area (In Sq. feet) : </span>{data?.approx_area}</div>
                        <div><span className='font-semibold'>Firecrack Machinary Types : </span>{data?.fire_machine}</div>
                    </div>
                </Panel>
            </PanelGroup>
        </div>
    )
}
