import React from 'react'

export default function Loader() {
  return (
    <div className="load-row">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}
