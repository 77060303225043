import axios from "axios";
// export const customRequest = axios.create({
//     baseURL: 'http://monster1.distronix.in:11100',
//     headers: { "Content-Type": "application/json" }
// });;

export const customRequest = axios.create({
    baseURL: 'https://fcmu.wbpcb.gov.in:11443',
    
});

export const user = JSON.parse(localStorage.getItem('user'));