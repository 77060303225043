import React, { useEffect, useState } from "react";
import { wbpcb } from "../../assets";
import Carousel, { CarouselItem } from "../../components/Carousel/Carousel";
import "rsuite/dist/rsuite-no-reset.min.css";
// import { policestation } from "../../Data/policestation";
// import { district } from "../../Data/district";
import { useLocation, useNavigate } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import { customRequest } from "../../Data/auth";
import { generateRefreshToken } from "../../Data/generateToken";

export default function Form() {
  const location = useLocation();
  const [police, setPolice] = useState([]);
  const [loc, setLoc] = useState("");
  const [district, setDistrict] = useState([]);
  const mobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  const pin = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;

  const successCallback = (position) => {
    let api_key = "7ae3daa9f6d1400cbfaf1262e653f499";
    let api_url = "https://api.opencagedata.com/geocode/v1/json";
    let req_url =
      api_url +
      "?" +
      "key=" +
      api_key +
      "&q=" +
      encodeURIComponent(
        position?.coords.latitude + "," + position?.coords.longitude
      ) +
      "&pretty=1" +
      "&no_annotation=1" +
      "&language=en";
    axios
      .get(req_url)
      .then((response) =>
        setLoc(
          response.data?.results[0].formatted,
          localStorage.setItem("loc", response.data?.results[0].formatted)
        )
      )
      .catch((error) => console.log(error.message));
  };

  const errorCallback = (error) => {
    // console.log(error);
  };

  const [state, setState] = useState({
    enter_name: location.state?.data.enter_name
      ? location.state?.data.enter_name
      : "",
    addr_name: location.state?.data.addr_name
      ? location.state?.data.addr_name
      : "",
    district: location.state?.data.district
      ? location.state?.data.district
      : "",
    police_station: location.state?.data.police_station
      ? location.state?.data.police_station
      : "",
    pincode: location.state?.data.pincode ? location.state?.data.pincode : "",
    own_name: location.state?.data.own_name
      ? location.state?.data.own_name
      : "",
    mob_number: location.state?.data.mob_number
      ? location.state?.data.mob_number
      : "",
    trade_license: location.state?.data
      ? `${location.state.data.trade_license}`
      : "",
    trade_license_number: location.state?.data.trade_license_number
      ? location.state?.data.trade_license_number
      : "",
    trade_license_photo: location.state?.data.trade_license_photo
      ? location.state?.data.trade_license_photo
      : "",
    capacity: location.state?.data ? `${location.state?.data.capacity}` : "",
    dm_permission: location.state?.data
      ? `${location.state?.data.dm_permission}`
      : "",
    dm_license: location.state?.data.dm_license
      ? location.state?.data.dm_license
      : "",
    dm_lic_photo: location.state?.data.dm_lic_photo
      ? location.state?.data.dm_lic_photo
      : "",
    peso_permission: location.state?.data
      ? `${location.state?.data.peso_permission}`
      : "",
    peso_license_no: location.state?.data.peso_license_no
      ? location.state?.data.peso_license_no
      : "",
    peso_lic_photo: location.state?.data.peso_lic_photo
      ? location.state?.data.peso_lic_photo
      : "",
    neeri_form: location.state?.data
      ? `${location.state?.data.neeri_form}`
      : "",
    neeri_license_no: location.state?.data.neeri_license_no
      ? location.state?.data.neeri_license_no
      : "",
    neeri_lic_photo: location.state?.data.neeri_lic_photo
      ? location.state?.data.neeri_lic_photo
      : "",
    noc_license: location.state?.data
      ? `${location.state?.data.noc_license}`
      : "",
    noc_license_no: location.state?.data.noc_license_no
      ? location.state?.data.noc_license_no
      : "",
    noc_lic_photo: location.state?.data.noc_lic_photo
      ? location.state?.data.noc_lic_photo
      : "",
    pcbcte_cto: location.state?.data
      ? `${location.state?.data.pcbcte_cto}`
      : "",
    pcb_cto: location.state?.data.pcb_cto ? location.state?.data.pcb_cto : "",
    pcb_cto_photo: location.state?.data.pcb_cto_photo
      ? location.state?.data.pcb_cto_photo
      : "",
    geo_location: location.state?.data.geo_location
      ? location.state?.data.geo_location
      : "",
    enter_photo: location.state?.data.enter_photo
      ? location.state?.data.enter_photo
      : "",
    approx_area: location.state?.data.approx_area
      ? location.state?.data.approx_area
      : "",
    fire_machine: location.state?.data.fire_machine
      ? location.state?.data.fire_machine
      : "",
  });

  const [sendData, setSendData] = useState(state);

  const [imageData, setImageData] = useState(
    location.state?.imageData ? location.state?.imageData : {}
  );

  function imageUploaded(file, name) {
    setImageData({ ...imageData, [`${name}`]: file });
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const handleChange = async (e, key) => {
    const { name, value, files } = e.target;
    if (files) {
      const file = await resizeFile(files[0]);
      imageUploaded(file, name);
    }

    setState((prevState) => ({
      ...prevState,
      [name]: value,
      // geo_location: loc,
      user_id: 1,
    }));

    setSendData((prevState) => ({
      ...prevState,
      [name]: files
        ? files[0].name
        : name === "mob_number"
        ? mobile.test(value)
          ? value
          : ""
        : name === "pincode"
        ? pin.test(value)
          ? value
          : ""
        : value,
    }));
  };

  const submitHandler = () => {
    window.location.pathname === "/add_new_form"
      ? navigate("/preview", {
          state: { preview: state, sendData: sendData, imageData: imageData },
        })
      : navigate("/update_form_preview", {
          state: {
            preview: state,
            sendData: sendData,
            id: location.state.data?.id,
            imageData: imageData,
          },
        });
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }, [state.district]);

  const data = [
    { label: "Name of Enterprise", name: "enter_name", type: "text" },
    { label: "Address of Enterprise", name: "addr_name", type: "text" },
    { label: "District List", name: "district" },
    { label: "Police Station", name: "police_station" },
    { label: "Pincode", name: "pincode", type: "number" },
    { label: "Name of Owner", name: "own_name", type: "text" },
    { label: "Mobile Number of Owner", name: "mob_number", type: "number" },
    { label: "Trade License", name: "trade_license" },
    {
      label: "Trade License Number",
      name: "trade_license_number",
      type: "text",
    },
    { label: "Trade License Photo", name: "trade_license_photo", type: "text" },
    { label: "Capacity (Greater than 500kg)", name: "capacity", type: "text" },
    { label: "DM Permission", name: "dm_permission", type: "text" },
    { label: "DM License Number", name: "dm_license", type: "text" },
    { label: "DM License Photo", name: "dm_lic_photo", type: "text" },
    { label: "PESO Permission", name: "peso_permission", type: "text" },
    { label: "PESO License Number", name: "peso_license_no", type: "text" },
    { label: "PESO License Photo", name: "peso_lic_photo", type: "text" },
    {
      label: "NEERI Formulation Obtained",
      name: "neeri_form",
      value: ["Yes", "No"],
      type: "text",
    },
    { label: "NEERI License Number", name: "neeri_license_no", type: "text" },
    { label: "NEERI License Photo", name: "neeri_lic_photo", type: "text" },
    {
      label: "NOC Fire License Obtained",
      name: "noc_license",
      value: ["Yes", "No"],
      type: "text",
    },
    { label: "NOC License Number", name: "noc_license_no", type: "text" },
    { label: "NOC License Photo", name: "noc_lic_photo", type: "text" },
    {
      label: "WBPCB CTE / CTO",
      name: "pcbcte_cto",
      value: ["Yes", "No"],
      type: "text",
    },
    { label: "WBPCB CTE / CTO Number", name: "pcb_cto" },
    { label: "WBPCB CTE / CTO Photo", name: "pcb_cto_photo" },
    { label: "Geo Location", name: "geo_location", type: "text" },
    { label: "Enterprise Photo", name: "enter_photo" },
    { label: "Approx Area (In Sq. feet)", name: "approx_area", type: "number" },
    { label: "Firecrack Machinary Types", name: "fire_machine", type: "text" },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    const district = () => {
      customRequest
        .get("/users/get_all_districts", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer${localStorage.getItem("accessToken")}`,
          },
        })
        .then((response) => {
          setDistrict(response.data?.districts);
        })
        .catch(async (e) => {
          if (e.response.status === 403) {
            const newTokenData = await generateRefreshToken(
              localStorage.getItem("refreshToken")
            );
            console.log(newTokenData.token);
            if (newTokenData.token) {
              localStorage.setItem("accessToken", newTokenData.token);
              district();
            } else {
              localStorage.clear();
            }
          } else if (e.response.status === 440) {
            localStorage.clear();
          }
        });
    };
    const police = () => {
      customRequest
        .post(
          "/users/get_police_station_from_district_name",
          {
            district: state?.district,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          setPolice(response?.data.police_stations);
        })
        .catch(async (e) => {
          if (e.response.status === 403) {
            const newTokenData = await generateRefreshToken(
              localStorage.getItem("refreshToken")
            );
            console.log(newTokenData.token);
            if (newTokenData.token) {
              localStorage.setItem("accessToken", newTokenData.token);
              district();
            } else {
              localStorage.clear();
            }
          } else if (e.response.status === 440) {
            localStorage.clear();
          }
        });
    };

    district();
    state?.district && police();
  }, [state?.district]);

  const [updateIndex, setUpdateIndex] = useState(0);

  return (
    <div>
      <div className="flex justify-center items-center text-center font-[Inter] relative">
        <div
          onClick={() => {
            navigate("/");
          }}
        >
          <i className="fa-solid fa-arrow-left text-2xl absolute left-5 top-10 z-50"></i>
        </div>
        {/* <div className='px-12 pt-8'>
                    <img src={wbpcb} alt="" className="w-fit" />
                </div> */}
        {/* <div className="text-lg pt-8 font-bold ">FIRECRACKER MANUFACTURE UNIT<br /> INFORMATION FORM</div> */}
      </div>
      <div className="flex justify-center flex-col items-center h-screen">
        <Carousel
          data={sendData}
          apiData={state}
          loc={loc}
          updateIndex={updateIndex}
        >
          <CarouselItem width={100} key={0}>
            <div className="flex flex-col text-black sm:w-full">
              <label className="text-xl ">
                {data[0].label}
                <span className="text-red-500">*</span>
              </label>
              <input
                autoComplete={false}
                type="text"
                value={state[data[0].name]}
                className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                name={data[0].name}
                onChange={handleChange}
                required
              />
              <div className="flex gap-4 mt-10">
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex + 1);
                  }}
                  disabled={state[data[0].name].length > 0 ? false : true}
                  className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    navigate("/");
                  }}
                  className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem key={1}>
            <div className="flex flex-col text-black sm:w-full">
              <label className="text-xl ">
                {data[1].label}
                <span className="text-red-500">*</span>
              </label>
              <input
                autoComplete={false}
                type="text"
                value={state[data[1].name]}
                className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                name={data[1].name}
                onChange={handleChange}
              />
              <div className="flex gap-4 mt-10">
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex + 1);
                  }}
                  disabled={state[data[1].name]?.length > 0 ? false : true}
                  className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex - 1);
                  }}
                  className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                >
                  Back
                </button>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem width={100} key={2}>
            <div className="flex flex-col text-black sm:w-full">
              <label className="text-xl ">
                {data[2].label}
                <span className="text-red-500">*</span>
              </label>
              <select
                autoComplete={false}
                className="border py-2 mt-4  w-80 outline-none focus:border-blue-700 transition duration-300 rounded"
                name={data[2].name}
                onChange={handleChange}
                value={state[data[2].name]}
              >
                <option value={""}>Select an option</option>
                {district?.map((data) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
              <div className="flex gap-4 mt-10">
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex + 1);
                  }}
                  disabled={state[data[2].name]?.length > 0 ? false : true}
                  className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex - 1);
                  }}
                  className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                >
                  Back
                </button>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem key={3}>
            <div className="flex flex-col text-black sm:w-full">
              <label className="text-xl ">
                {data[3].label}
                <span className="text-red-500">*</span>
              </label>
              <select
                autoComplete={false}
                className="border py-2 mt-4 w-80 outline-none focus:border-blue-700 transition duration-300 rounded"
                name={data[3].name}
                onChange={handleChange}
                value={state[data[3].name]}
              >
                <option value={""}>Select an option</option>
                {police?.map((data) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
              <div className="flex gap-4 mt-10">
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex + 1);
                  }}
                  disabled={state[data[3].name]?.length > 0 ? false : true}
                  className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex - 1);
                  }}
                  className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                >
                  Back
                </button>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem key={4}>
            <div className="flex flex-col text-black sm:w-full">
              <label className="text-xl ">
                {data[4].label}
                <span className="text-red-500">*</span>
              </label>
              <input
                autoComplete={false}
                type={data[4].type}
                maxLength={6}
                className="border-b-2 py-2  w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                onInput={(e) => {
                  return data[4].type === "number"
                    ? (e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6))
                    : e.target.value;
                }}
                value={state[data[4].name]}
                name={data[4].name}
                onChange={handleChange}
              />
              <div className="flex gap-4 mt-10">
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex + 1);
                  }}
                  disabled={state[data[4].name].length === 6 ? false : true}
                  className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex - 1);
                  }}
                  className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                >
                  Back
                </button>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem key={5}>
            <div className="flex flex-col text-black sm:w-full">
              <label className="text-xl ">
                {data[5].label}
                <span className="text-red-500">*</span>
              </label>
              <input
                autoComplete={false}
                type="text"
                className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                name={data[5].name}
                onChange={handleChange}
                value={state[data[5].name]}
              />
              <div className="flex gap-4 mt-10">
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex + 1);
                  }}
                  disabled={state[data[5].name]?.length > 0 ? false : true}
                  className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex - 1);
                  }}
                  className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                >
                  Back
                </button>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem key={6}>
            <div className="flex flex-col text-black sm:w-full">
              <label className="text-xl ">
                {data[6].label}
                <span className="text-red-500">*</span>
              </label>
              <input
                autoComplete={false}
                type={data[6].type}
                className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                onInput={(e) => {
                  return data[6].type === "number"
                    ? (e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10))
                    : e.target.value;
                }}
                name={data[6].name}
                onChange={handleChange}
                value={state[data[6].name]}
              />
              <div className="flex gap-4 mt-10">
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex + 1);
                  }}
                  disabled={state[data[6].name].length === 10 ? false : true}
                  className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex - 1);
                  }}
                  className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                >
                  Back
                </button>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem key={7}>
            <div className="flex flex-col text-black sm:w-full">
              <label className="text-xl ">
                {data[7].label}
                <span className="text-red-500">*</span>
              </label>
              <select
                autoComplete={false}
                className="border py-2 mt-4 w-80 outline-none focus:border-blue-700 transition duration-300 rounded"
                name={data[7].name}
                onChange={handleChange}
                value={state[data[7].name]}
              >
                <option value={""}>Select an option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
              <div className="flex gap-4 mt-10">
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex + 1);
                  }}
                  disabled={
                    state[data[7].name] !== undefined &&
                    state[data[7].name]?.length > 0
                      ? false
                      : true
                  }
                  className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex - 1);
                  }}
                  className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                >
                  Back
                </button>
              </div>
            </div>
          </CarouselItem>
          {state.trade_license === "true" ? (
            <CarouselItem key={8}>
              <div className="flex flex-col text-black sm:w-full">
                <label className="text-xl ">
                  {data[8].label}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  autoComplete={false}
                  type="text"
                  className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                  name={data[8].name}
                  onChange={handleChange}
                  value={state[data[8].name]}
                />
                <div className="flex gap-4 mt-10">
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex + 1);
                    }}
                    disabled={state[data[8].name].length > 0 ? false : true}
                    className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                  >
                    OK
                  </button>
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex - 1);
                    }}
                    className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                  >
                    Back
                  </button>
                </div>{" "}
              </div>
            </CarouselItem>
          ) : (
            <></>
          )}
          {state.trade_license === "true" ? (
            <CarouselItem key={9}>
              <div className="flex flex-col text-black sm:w-full">
                {!imageData?.trade_license_photo ? (
                  state.trade_license_photo ? (
                    <img
                      src={state.trade_license_photo}
                      alt=""
                      width={100}
                      height={100}
                    />
                  ) : (
                    ""
                  )
                ) : (
                  <img
                    src={imageData?.trade_license_photo}
                    alt=""
                    width={100}
                    height={100}
                  />
                )}
                <label className="text-xl ">
                  {data[9].label}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  autoComplete={false}
                  type="file"
                  accept="image/*"
                  capture="camera"
                  className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                  name={data[9].name}
                  onChange={handleChange}
                />
                <div className="flex gap-4 mt-10">
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex + 1);
                    }}
                    disabled={state[data[9].name]?.length > 0 ? false : true}
                    className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                  >
                    OK
                  </button>
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex - 1);
                    }}
                    className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                  >
                    Back
                  </button>
                </div>
              </div>
            </CarouselItem>
          ) : (
            <></>
          )}
          <CarouselItem key={10}>
            <div className="flex flex-col text-black sm:w-full">
              <label className="text-xl ">
                {data[10].label}
                <span className="text-red-500">*</span>
              </label>
              <select
                autoComplete={false}
                className="border py-2 mt-4 w-80 outline-none focus:border-blue-700 transition duration-300 rounded"
                name={data[10].name}
                onChange={handleChange}
                value={state[data[10].name]}
              >
                <option value={""}>Select an option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
              <div className="flex gap-4 mt-10">
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex + 1);
                  }}
                  disabled={
                    state[data[10].name] !== undefined &&
                    state[data[10].name]?.length > 0
                      ? false
                      : true
                  }
                  className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex - 1);
                  }}
                  className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                >
                  Back
                </button>
              </div>
            </div>
          </CarouselItem>
          {state.capacity === "false" ? (
            <CarouselItem key={11}>
              <div className="flex flex-col text-black sm:w-full">
                <label className="text-xl ">
                  {data[11].label}
                  <span className="text-red-500">*</span>
                </label>
                <select
                  autoComplete={false}
                  className="border py-2 mt-4 w-80 outline-none focus:border-blue-700 transition duration-300 rounded"
                  name={data[11].name}
                  onChange={handleChange}
                  value={state[data[11].name]}
                >
                  <option value={""}>Select an option</option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
                <div className="flex gap-4 mt-10">
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex + 1);
                    }}
                    disabled={
                      state[data[11].name] !== undefined &&
                      state[data[11].name]?.length > 0
                        ? false
                        : true
                    }
                    className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                  >
                    OK
                  </button>
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex - 1);
                    }}
                    className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                  >
                    Back
                  </button>
                </div>
              </div>
            </CarouselItem>
          ) : (
            <></>
          )}
          {state.capacity === "false" && state.dm_permission === "true" ? (
            <CarouselItem key={12}>
              <div className="flex flex-col text-black sm:w-full">
                <label className="text-xl ">
                  {data[12].label}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  autoComplete={false}
                  type="text"
                  className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                  name={data[12].name}
                  value={state[data[12].name]}
                  onChange={handleChange}
                />
                <div className="flex gap-4 mt-10">
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex + 1);
                    }}
                    disabled={state[data[12].name]?.length > 0 ? false : true}
                    className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                  >
                    OK
                  </button>
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex - 1);
                    }}
                    className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                  >
                    Back
                  </button>
                </div>
              </div>
            </CarouselItem>
          ) : (
            <></>
          )}
          {state.capacity === "false" && state.dm_permission === "true" ? (
            <CarouselItem key={13}>
              <div className="flex flex-col text-black sm:w-full ">
                {!imageData?.dm_lic_photo ? (
                  state?.dm_lic_photo ? (
                    <img
                      src={state?.dm_lic_photo}
                      alt=""
                      width={100}
                      height={100}
                    />
                  ) : (
                    ""
                  )
                ) : (
                  <img
                    src={imageData?.dm_lic_photo}
                    alt=""
                    width={100}
                    height={100}
                  />
                )}
                <label className="text-xl ">
                  {data[13].label}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  autoComplete={false}
                  type="file"
                  accept="image/*"
                  capture="camera"
                  className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                  name={data[13].name}
                  onChange={handleChange}
                  // value={state[data[13].name]}
                />
                <div className="flex gap-4 mt-10">
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex + 1);
                    }}
                    disabled={state[data[13].name]?.length > 0 ? false : true}
                    className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                  >
                    OK
                  </button>
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex - 1);
                    }}
                    className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                  >
                    Back
                  </button>
                </div>
              </div>
            </CarouselItem>
          ) : (
            <></>
          )}

          {state.capacity === "true" ? (
            <CarouselItem key={14}>
              <div className="flex flex-col text-black sm:w-full">
                <label className="text-xl ">
                  {data[14].label}
                  <span className="text-red-500">*</span>
                </label>
                <select
                  autoComplete={false}
                  className="border py-2 mt-4 w-80 outline-none focus:border-blue-700 transition duration-300 rounded"
                  name={data[14].name}
                  onChange={handleChange}
                  value={state[data[14].name]}
                >
                  <option value={""}>Select an option</option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
                <div className="flex gap-4 mt-10">
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex + 1);
                    }}
                    disabled={
                      state[data[14].name] !== undefined &&
                      state[data[14].name]?.length > 0
                        ? false
                        : true
                    }
                    className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                  >
                    OK
                  </button>
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex - 1);
                    }}
                    className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                  >
                    Back
                  </button>
                </div>
              </div>
            </CarouselItem>
          ) : (
            <></>
          )}
          {state.capacity === "true" && state.peso_permission === "true" ? (
            <CarouselItem key={15}>
              <div className="flex flex-col text-black sm:w-full">
                <label className="text-xl ">
                  {data[15].label}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  autoComplete={false}
                  type="text"
                  className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                  name={data[15].name}
                  onChange={handleChange}
                  value={state[data[15].name]}
                />
                <div className="flex gap-4 mt-10">
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex + 1);
                    }}
                    disabled={state[data[15].name]?.length > 0 ? false : true}
                    className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                  >
                    OK
                  </button>
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex - 1);
                    }}
                    className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                  >
                    Back
                  </button>
                </div>
              </div>
            </CarouselItem>
          ) : (
            <></>
          )}
          {state.capacity === "true" && state.peso_permission === "true" ? (
            <CarouselItem key={16}>
              <div className="flex flex-col text-black sm:w-full">
                {!imageData?.peso_lic_photo ? (
                  state.peso_lic_photo ? (
                    <img
                      src={state?.peso_lic_photo}
                      alt=""
                      width={100}
                      height={100}
                    />
                  ) : (
                    ""
                  )
                ) : (
                  <img
                    src={imageData?.peso_lic_photo}
                    alt=""
                    width={100}
                    height={100}
                  />
                )}
                <label className="text-xl ">
                  {data[16].label}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  autoComplete={false}
                  type="file"
                  accept="image/*"
                  capture="camera"
                  className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                  name={data[16].name}
                  onChange={handleChange}
                  // value={state[data[17].name]}
                />
                <div className="flex gap-4 mt-10">
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex + 1);
                    }}
                    disabled={state[data[16].name]?.length > 0 ? false : true}
                    className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                  >
                    OK
                  </button>
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex - 1);
                    }}
                    className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                  >
                    Back
                  </button>
                </div>
              </div>
            </CarouselItem>
          ) : (
            <></>
          )}
          <CarouselItem key={17}>
            <div className="flex flex-col text-black sm:w-full">
              <label className="text-xl ">
                {data[17].label}
                <span className="text-red-500">*</span>
              </label>
              <select
                autoComplete={false}
                className="border py-2 mt-4 w-80 outline-none focus:border-blue-700 transition duration-300 rounded"
                name={data[17].name}
                onChange={handleChange}
                value={state[data[17].name]}
              >
                <option value={""}>Select an option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
              <div className="flex gap-4 mt-10">
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex + 1);
                  }}
                  disabled={
                    state[data[17].name] !== undefined &&
                    state[data[17].name]?.length > 0
                      ? false
                      : true
                  }
                  className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex - 1);
                  }}
                  className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                >
                  Back
                </button>
              </div>
            </div>
          </CarouselItem>

          {state.neeri_form === "true" ? (
            <CarouselItem key={18}>
              <div className="flex flex-col text-black sm:w-full">
                <label className="text-xl ">
                  {data[18].label}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  autoComplete={false}
                  type="text"
                  className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                  name={data[18].name}
                  onChange={handleChange}
                  value={state[data[18].name]}
                />
                <div className="flex gap-4 mt-10">
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex + 1);
                    }}
                    disabled={state[data[18].name].length > 0 ? false : true}
                    className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                  >
                    OK
                  </button>
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex - 1);
                    }}
                    className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                  >
                    Back
                  </button>
                </div>
              </div>
            </CarouselItem>
          ) : (
            <></>
          )}
          {state.neeri_form === "true" ? (
            <CarouselItem key={19}>
              <div className="flex flex-col text-black sm:w-full">
                {!imageData?.neeri_lic_photo ? (
                  state?.neeri_lic_photo ? (
                    <img
                      src={state?.neeri_lic_photo}
                      alt=""
                      width={100}
                      height={100}
                    />
                  ) : (
                    ""
                  )
                ) : (
                  <img
                    src={imageData.neeri_lic_photo}
                    alt=""
                    width={100}
                    height={100}
                  />
                )}
                <label className="text-xl ">
                  {data[19].label}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  autoComplete={false}
                  type="file"
                  accept="image/*"
                  capture="camera"
                  className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                  name={data[19].name}
                  onChange={handleChange}
                  // value={state[data[20].name]}
                />
                <div className="flex gap-4 mt-10">
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex + 1);
                    }}
                    disabled={state[data[19].name]?.length > 0 ? false : true}
                    className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                  >
                    OK
                  </button>
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex - 1);
                    }}
                    className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                  >
                    Back
                  </button>
                </div>
              </div>
            </CarouselItem>
          ) : (
            <></>
          )}
          <CarouselItem key={20}>
            <div className="flex flex-col text-black sm:w-full">
              <label className="text-xl ">
                {data[20].label}
                <span className="text-red-500">*</span>
              </label>
              <select
                autoComplete={false}
                className="border py-2 mt-4 w-80 outline-none focus:border-blue-700 transition duration-300 rounded"
                name={data[20].name}
                onChange={handleChange}
                value={state[data[20].name]}
              >
                <option value={""}>Select an option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
              <div className="flex gap-4 mt-10">
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex + 1);
                  }}
                  disabled={
                    state[data[20].name] !== undefined &&
                    state[data[20].name]?.length > 0
                      ? false
                      : true
                  }
                  className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex - 1);
                  }}
                  className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                >
                  Back
                </button>
              </div>
            </div>
          </CarouselItem>

          {state.noc_license === "true" ? (
            <CarouselItem key={21}>
              <div className="flex flex-col text-black sm:w-full">
                <label className="text-xl ">
                  {data[21].label}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  autoComplete={false}
                  type="text"
                  className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                  name={data[21].name}
                  onChange={handleChange}
                  value={state[data[21].name]}
                />
                <div className="flex gap-4 mt-10">
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex + 1);
                    }}
                    disabled={state[data[21].name].length > 0 ? false : true}
                    className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                  >
                    OK
                  </button>
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex - 1);
                    }}
                    className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                  >
                    Back
                  </button>
                </div>
              </div>
            </CarouselItem>
          ) : (
            <></>
          )}
          {state.noc_license === "true" ? (
            <CarouselItem key={22}>
              <div className="flex flex-col text-black sm:w-full">
                {!imageData?.noc_lic_photo ? (
                  state?.noc_lic_photo ? (
                    <img
                      src={state?.noc_lic_photo}
                      alt=""
                      width={100}
                      height={100}
                    />
                  ) : (
                    ""
                  )
                ) : (
                  <img
                    src={imageData.noc_lic_photo}
                    alt=""
                    width={100}
                    height={100}
                  />
                )}
                <label className="text-xl ">
                  {data[22].label}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="file"
                  accept="image/*"
                  capture="camera"
                  className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                  name={data[22].name}
                  onChange={handleChange}
                  // value={state[data[22].name]}
                />
                <div className="flex gap-4 mt-10">
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex + 1);
                    }}
                    disabled={state[data[22].name]?.length > 0 ? false : true}
                    className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                  >
                    OK
                  </button>
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex - 1);
                    }}
                    className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                  >
                    Back
                  </button>
                </div>
              </div>
            </CarouselItem>
          ) : (
            <></>
          )}
          <CarouselItem key={23}>
            <div className="flex flex-col text-black sm:w-full">
              <label className="text-xl ">
                {data[23].label}
                <span className="text-red-500">*</span>
              </label>
              <select
                autoComplete={false}
                className="border py-2 mt-4 w-80 outline-none focus:border-blue-700 transition duration-300 rounded"
                name={data[23].name}
                onChange={handleChange}
                value={state[data[23].name]}
              >
                <option value={""}>Select an option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
              <div className="flex gap-4 mt-10">
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex + 1);
                  }}
                  disabled={
                    state[data[23].name]?.length > 0 &&
                    state[data[7].name]?.length > 0
                      ? false
                      : true
                  }
                  className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex - 1);
                  }}
                  className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                >
                  Back
                </button>
              </div>
            </div>
          </CarouselItem>
          {state.pcbcte_cto === "true" ? (
            <CarouselItem key={24}>
              <div className="flex flex-col text-black sm:w-full">
                <label className="text-xl ">
                  {data[24].label}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  autoComplete={false}
                  type="text"
                  className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                  name={data[24].name}
                  onChange={handleChange}
                  value={state[data[24].name]}
                />
                <div className="flex gap-4 mt-10">
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex + 1);
                    }}
                    disabled={state[data[24].name]?.length > 0 ? false : true}
                    className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                  >
                    OK
                  </button>
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex - 1);
                    }}
                    className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                  >
                    Back
                  </button>
                </div>
              </div>
            </CarouselItem>
          ) : (
            <></>
          )}

          {state.pcbcte_cto === "true" ? (
            <CarouselItem key={25}>
              <div className="flex flex-col text-black sm:w-full">
                {!imageData?.pcb_cto_photo ? (
                  state?.pcb_cto_photo ? (
                    <img
                      src={state?.pcb_cto_photo}
                      alt=""
                      width={100}
                      height={100}
                    />
                  ) : (
                    ""
                  )
                ) : (
                  <img
                    src={imageData.pcb_cto_photo}
                    alt=""
                    width={100}
                    height={100}
                  />
                )}
                <label className="text-xl ">
                  {data[25].label}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  autoComplete={false}
                  type="file"
                  accept="image/*"
                  capture="camera"
                  className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                  name={data[25].name}
                  onChange={handleChange}
                  // value={state[data[25].name]}
                />
                <div className="flex gap-4 mt-10">
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex + 1);
                    }}
                    disabled={state[data[25].name]?.length > 0 ? false : true}
                    className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                  >
                    OK
                  </button>
                  <button
                    onClick={() => {
                      setUpdateIndex((updateIndex) => updateIndex - 1);
                    }}
                    className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                  >
                    Back
                  </button>
                </div>
              </div>
            </CarouselItem>
          ) : (
            <></>
          )}
          <CarouselItem key={26}>
            <div className="flex flex-col text-black sm:w-full">
              <label className="text-xl ">
                {data[26].label}
                <span className="text-red-500">*</span>
              </label>
              <input
                autoComplete={false}
                type="text"
                className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                name={data[26].name}
                onChange={handleChange}
                value={loc}
              />
              <div className="flex gap-4 mt-10">
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex + 1);
                  }}
                  disabled={false}
                  className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex - 1);
                  }}
                  className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                >
                  Back
                </button>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem key={27}>
            <div className="flex flex-col text-black sm:w-full">
              {!imageData?.enter_photo ? (
                state?.enter_photo ? (
                  <img
                    src={state?.enter_photo}
                    alt=""
                    width={100}
                    height={100}
                  />
                ) : (
                  ""
                )
              ) : (
                <img
                  src={imageData.enter_photo}
                  alt=""
                  width={100}
                  height={100}
                />
              )}
              <label className="text-xl ">
                {data[27].label}
                <span className="text-red-500">*</span>
              </label>
              <input
                autoComplete={false}
                type="file"
                accept="image/*"
                capture="camera"
                className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                name={data[27].name}
                onChange={handleChange}
                // value={state[data[27].name]}
              />
              <div className="flex gap-4 mt-10">
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex + 1);
                  }}
                  disabled={state[data[27].name]?.length > 0 ? false : true}
                  className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex - 1);
                  }}
                  className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                >
                  Back
                </button>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem key={28}>
            <div className="flex flex-col text-black sm:w-full">
              <label className="text-xl ">
                {data[28].label}
                <span className="text-red-500">*</span>
              </label>
              <input
                autoComplete={false}
                type={data[28].type}
                className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300 whitespace-pre-line"
                name={data[28].name}
                onInput={(e) => {
                  return data[6].type === "number"
                    ? (e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10))
                    : e.target.value;
                }}
                onChange={handleChange}
                value={state[data[28].name]}
              />
              <div className="flex gap-4 mt-10">
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex + 1);
                  }}
                  disabled={
                    state[data[28].name]?.toString().length > 0 ? false : true
                  }
                  className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex - 1);
                  }}
                  className="bg-transparent hover:bg-blue-100 hover:text-black font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                >
                  Back
                </button>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem key={29}>
            <div className="flex flex-col text-black sm:w-full">
              <label className="text-xl ">
                {data[29].label}
                <span className="text-red-500">*</span>
              </label>
              <input
                autoComplete={false}
                type="text"
                className="border-b-2 py-2 w-80 outline-none focus:border-b-2 focus:border-blue-700 transition duration-300"
                name={data[29].name}
                onChange={handleChange}
                value={state[data[29].name]}
              />
              <div className="flex gap-4 mt-10">
                <button
                  onClick={() => {
                    // setUpdateIndex(updateIndex => updateIndex + 1);
                    submitHandler();
                  }}
                  disabled={state[data[29].name]?.length > 0 ? false : true}
                  className="bg-blue-500 hover:bg-blue-700 text-white text-base font-bold py-2 px-12 rounded"
                >
                  Submit
                </button>
                <button
                  onClick={() => {
                    setUpdateIndex((updateIndex) => updateIndex - 1);
                  }}
                  className="bg-transparent hover:bg-blue-100 hover:text-black text-base font-semibold py-2 px-6 border border-gray-200 mr-4 hover:border-transparent rounded"
                >
                  Back
                </button>
              </div>
            </div>
          </CarouselItem>
        </Carousel>
      </div>
    </div>
  );
}
