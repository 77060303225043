import React, { useState, useEffect } from "react";
import { customRequest } from "../../Data/auth";
import axios from "axios";
import LocationModal from "../LocationModal/LocationModal";

export default function Login({ setNav, setMobile, setOtp }) {
  const [state, setState] = useState({ mobile_no: "" });
  const [loc, setLoc] = useState("");

  const successCallback = (position) => {
    let api_key = "7ae3daa9f6d1400cbfaf1262e653f499";
    let api_url = "https://api.opencagedata.com/geocode/v1/json";
    let req_url =
      api_url +
      "?" +
      "key=" +
      api_key +
      "&q=" +
      encodeURIComponent(
        position?.coords.latitude + "," + position?.coords.longitude
      ) +
      "&pretty=1" +
      "&no_annotation=1" +
      "&language=en";
    axios
      .get(req_url)
      .then((response) => (setLoc(response.data?.results[0].formatted), localStorage.setItem('loc', response.data?.results[0].formatted)))
      .catch((error) => console.log(error.message));
  };
  
  navigator.geolocation.watchPosition((position) => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    console.log(position.coords.latitude, position.coords.longitude);
  });

  const errorCallback = (error) => {
    console.log(error);
  };


  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }, []);

  const otpHandler = () => {
    customRequest
      .post("/users/otp_login", {
        mobile_number: state.mobile_no,
      })
      .then((response) => {
        // console.log(response);
        setNav(true);
        setMobile(state.mobile_no);
      })
      .catch((e) => console.log(e));
  };

  // Check the current state of location permission
navigator.permissions.query({name: 'geolocation'}).then(function(result) {
  if (result.state == 'granted') {
    // Location permission is granted
    // You can use navigator.geolocation.getCurrentPosition() here
  } else if (result.state == 'prompt') {
    // Location permission is not decided yet
    // You can request it using navigator.geolocation.getCurrentPosition()
  } else if (result.state == 'denied') {
    // Location permission is denied
    // You can show a message or a button to instruct the user how to enable it
  }
});




  return (
    <div className="bg-white rounded-tl-3xl rounded-tr-3xl mt-16 p-10 h-[61%]">
      {loc ? (
        <div className="flex flex-col gap-10 pt-2">
          <div className="border p-3 rounded-xl w-full font-bold border-[#B2AFAF]">
            India(+91)
          </div>
          <div className="flex flex-col text-black sm:w-full">
            <input
              type={"number"}
              maxLength={10}
              placeholder="Mobile No."
              className="border p-3 outline-none rounded-xl w-full font-bold border-[#B2AFAF] focus:border-blue-700 transition duration-300"
              onInput={(e) => {
                return e.target.type === "number"
                  ? (e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10))
                  : e.target.value;
              }}
              name="mobile_no"
              value={state.mobile_no}
              onChange={(e) => setState({ mobile_no: e.target.value })}
            />
          </div>
          <div
            className="p-3 rounded-xl w-full font-bold bg-[#D6E9EC] text-center"
            onClick={otpHandler}
          >
            Send OTP
          </div>
        </div>
      ) : (
        <div className="font-semibold text-center mt-10 flex flex-col items-center gap-5">
          Click here to see, how to enable location if you denied it.
          <LocationModal />
        </div>
      )}
    </div>
  );
}
